import _util from "util";
import _inherits_browser from "./inherits_browser.js";
var exports = {};

try {
  var util = _util;
  /* istanbul ignore next */

  if (typeof util.inherits !== "function") throw "";
  exports = util.inherits;
} catch (e) {
  /* istanbul ignore next */
  exports = _inherits_browser;
}

export default exports;